import React from "react"
import { Text } from 'react-native'
import { Table, Card, Row, Col } from "react-bootstrap"
import { MdOutlineTrendingUp, MdOutlineTrendingDown, MdOutlineTrendingFlat } from "react-icons/md"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'


class Bi extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bis: [],
            today: [],
            clients: [],
            clients_active: [],
            clients_active_ticket: 0,
            clients_repeat_ticket: 0,
            clients_new_ticket: 0,
            clients_dnr_ticket: 0
        }

        this.bis2 = []
        this.importDateDiaries = []

        this.getClientsDone = false
        this.getBisDone = false
        this.getImportDateDiariesDone = false
    }

    componentDidMount() {
        this.getClients()
        this.getBis()
        this.getImportDateDiaries()

    }

    componentWillUnmount() {

    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("userId")

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("tokenBiwhot")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    clients: data,
                    clients_active: data.filter(client => client.active),
                    clients_active_ticket: data
                        .filter(client => client.active)
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_repeat_ticket: data
                        .filter(client => client.active && client.last_ticket > 0 && client.last_last_ticket > 0 && client.last_ticket !== client.last_last_ticket)
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_new_ticket: data
                        .filter((client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')))
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_dnr_ticket: data
                        .filter(client => !client.active)
                        .reduce((n, { last_active_ticket }) => n + last_active_ticket, 0),
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getClientsDone = true
            })
    }

    getBis = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("userId")

        fetch(BASE_URL + "/biDiary/" + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("tokenBiwhot")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    bis: data,
                    today: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })[0]
                })

                const dataSorted = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })

                this.bis2 = dataSorted
                    .map(function (dataSortedOne, index) {
                        if (typeof dataSorted[index + 1] !== 'undefined') {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                total_clients: dataSorted[index].total_clients,
                                total_actives: dataSorted[index].total_actives,
                                total_actives_diff: (dataSorted[index + 1].total_actives) > 0 ? (dataSorted[index].total_actives - dataSorted[index + 1].total_actives) / (dataSorted[index + 1].total_actives) : 0,
                                total_repeat: dataSorted[index].total_repeat,
                                total_repeat_diff: (dataSorted[index + 1].total_repeat) > 0 ? (dataSorted[index].total_repeat - dataSorted[index + 1].total_repeat) / (dataSorted[index + 1].total_repeat) : 0,
                                total_newclient: dataSorted[index].total_newclient,
                                total_newclient_diff: (dataSorted[index + 1].total_newclient) > 0 ? (dataSorted[index].total_newclient - dataSorted[index + 1].total_newclient) / (dataSorted[index + 1].total_newclient) : 0,
                                total_dnr: dataSorted[index].total_dnr,
                                total_dnr_diff: (dataSorted[index + 1].total_dnr) > 0 ? (dataSorted[index].total_dnr - dataSorted[index + 1].total_dnr) / (dataSorted[index + 1].total_dnr) : 0
                            }
                        } else {
                            return {
                                date: dataSorted[index].date,
                                total_clients: dataSorted[index].total_clients,
                                total_actives: dataSorted[index].total_actives,
                                total_actives_diff: 0,
                                total_repeat: dataSorted[index].total_repeat,
                                total_repeat_diff: 0,
                                total_newclient: dataSorted[index].total_newclient,
                                total_newclient_diff: 0,
                                total_dnr: dataSorted[index].total_dnr,
                                total_dnr_diff: 0
                            }
                        }
                    })

            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getBisDone = true
            })

    }

    getImportDateDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("userId")

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("tokenBiwhot")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    importDateDiaries: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })
                        .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') !== moment(new Date()).format('MMMM/YYYY'))
                })

                const dataSorted = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })
                    .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') !== moment(new Date()).format('MMMM/YYYY'))

                this.importDateDiaries = dataSorted
                    .map(function (dataSortedOne, index) {
                        if (typeof dataSorted[index + 1] !== 'undefined') {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                visitors: dataSorted[index].bestClients.total,
                                visitors_diff: (dataSorted[index + 1].bestClients.total) > 0 ? (dataSorted[index].bestClients.total - dataSorted[index + 1].bestClients.total) / (dataSorted[index + 1].bestClients.total) : 0
                            }
                        } else {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                visitors: dataSorted[index].bestClients.total,
                                visitors_diff: 0
                            }
                        }
                    })

            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getImportDateDiariesDone = true
            })
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.getClientsDone && this.getBisDone && this.getImportDateDiariesDone
                        ?
                        <>
                            <h3>Business Intelligence</h3>

                            <Row xs={1} md={5} className="g-4">
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes pagantes</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Visita com gasto no mês passado
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? (this.importDateDiaries[0]?Math.abs(((this.importDateDiaries[0].visitors) / (this.state.clients_active.length) * 100).toFixed(0)):0) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? (this.importDateDiaries[0]?Math.abs(((this.importDateDiaries[0].visitors) / (this.state.clients_active.length) * 100).toFixed(0)):0) : 0} %</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.importDateDiaries[0] ? this.importDateDiaries[0].visitors : '0'}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.importDateDiaries[0]?(this.state.clients_active_ticket / this.importDateDiaries[0].visitors).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'):0)}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_active_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes recorrêntes</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Visitou nos 2 últimos meses seguidos
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? Math.abs((this.state.today.total_repeat / this.state.clients_active.length * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? Math.abs((this.state.today.total_repeat / this.state.clients_active.length * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.today.total_repeat}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_repeat_ticket / this.state.today.total_repeat).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_repeat_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes novos</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Primeira visita foi no mês passado
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? Math.abs(((this.state.today.total_newclient) / (this.state.clients_active.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? Math.abs(((this.state.today.total_newclient) / (this.state.clients_active.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.today.total_newclient}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_new_ticket / this.state.today.total_newclient).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_new_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes ativos</Card.Title><Text style={{ color: 'gray' }}>
                                                Última visita a menos de 90 dias
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients.length > 0 ? Math.abs(((this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients.length > 0 ? Math.abs(((this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.clients_active.length}</b> de {this.state.clients.length} na base total <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_active_ticket / this.state.today.total_actives).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_active_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes que não retornaram</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Última visita a mais de 90 dias
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients.length > 0 ? Math.abs(((this.state.clients.length - this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#ed4545',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients.length > 0 ? Math.abs(((this.state.clients.length - this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.clients.length - this.state.clients_active.length}</b> de {this.state.clients.length} na base total <br></br>
                                                <br></br>
                                                <b>Perda/cliente | R$ {(this.state.clients_dnr_ticket / this.state.today.total_dnr).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Perda total | R$ {(this.state.clients_dnr_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <div>
                                <br></br>
                            </div>

                            <h3>Histórico de B.I.</h3>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Clientes pagantes</th><th></th>
                                        <th>Clientes recorrentes</th><th></th>
                                        <th>Clientes novos</th><th></th>
                                        <th>Clientes ativos</th><th></th>
                                        <th>Clientes que não retornaram</th><th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.bis2
                                            .map((bi, index) =>
                                                <tr key={bi.date}>
                                                    <td>{moment.utc(new Date(bi.date)).format('MMMM/YYYY')}</td>
                                                    <td>{this.importDateDiaries[index].visitors} na base importada</td><td>{this.importDateDiaries[index].visitors_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : this.importDateDiaries[index].visitors_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(this.importDateDiaries[index].visitors_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_repeat} na base importada</td><td>{bi.total_repeat_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_repeat_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_repeat_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_newclient} na base importada</td><td>{bi.total_newclient_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_newclient_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_newclient_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_actives} na base total</td><td>{bi.total_actives_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_actives_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_actives_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_dnr} de {bi.total_clients} na base importada</td><td>{bi.total_dnr_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_dnr_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_dnr_diff * 100).toFixed(0)}%</td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Bi;