import React from "react"
import { Table } from "react-bootstrap"
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'
// import Table from "react-table-lite"


class Clients extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            pageReady: false
        }

        this.clientsPhone = []
        this.tryPhone2 = []
        this.upClientsPhones = []

    }

    async componentDidMount() {
        this.getClients()
        // await this.sleep(5000)
        // this.wappNumberMap()
    }

    componentWillUnmount() {

    }


    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("userId")
        var clientLength = 999999999

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("tokenBiwhot")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    clients: data.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1)
                })
                clientLength = data.length
                this.clientsPhone = data
            })
            .catch(rejected => {
                console.log(rejected);
            })
            .finally(data => {
                if (clientLength !== 999999999) {
                    this.setState({ pageReady: true })
                    console.log(clientLength)
                }
            })

    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    updateClient = (data) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("tokenBiwhot")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    console.log('UPDATING CLIENTS - COMPLETED!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    wappNumberMap = async () => {


        console.log('PHONE 1')
        console.log(this.clientsPhone.length)
        for (let index = 0; index < this.clientsPhone.length; index++) {
            console.log(index + ' - PHONE 1')
            const element = this.clientsPhone[index]
            this.checkWappNumber(element, 'PHONE 1')
            await this.sleep(300)
        }

        console.log('PHONE 2')
        console.log(this.tryPhone2.length)
        for (let index = 0; index < this.tryPhone2.length; index++) {
            console.log(index + ' - PHONE 2')
            const element = this.tryPhone2[index]
            this.checkWappNumber(element, 'PHONE 2')
            await this.sleep(300)
        }

        console.log('END VECTOR')
        console.log(this.upClientsPhones)

        console.log('UPDATING CLIENTS')
        this.updateClient(this.upClientsPhones)

    }

    checkWappNumber = (client, trying) => {

        const phone = trying !== 'PHONE 2' 
                ? ((((this.phoneNumberCorrection(client.phone1)).replace('+', '')).replace(' ', '')).replace(' ', '')).replace(' ', '')
                : ((((this.phoneNumberCorrection(client.client.phone1)).replace('+', '')).replace(' ', '')).replace(' ', '')).replace(' ', '')

        const instance_id = localStorage.getItem("wInstance")
        const token = localStorage.getItem("wToken")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/action/is-registered-user", {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            },
            body: JSON.stringify({ contactId: phone + '@c.us' })
        })
            .then(res => res.json())
            .then(data => {
                if (!data.data.data.isRegisteredUser && trying !== 'PHONE 2') {
                    if (client.phone2 !== '') {
                        this.tryPhone2.push({ client: client })
                    }
                } else {
                    if (trying === 'PHONE 2') {
                        this.upClientsPhones.push({
                            _id: client.client._id,
                            name: client.client.name,
                            phone1: client.client.phone2,
                            phone2: client.client.phone1
                        })
                    } else {
                        console.log('Phone2 check: ' + this.tryPhone2.length)
                    }
                }

            })
            .catch(err => console.error('error:' + err))
    }

    uppercaseEachWords = (sentence) => {

        if (typeof sentence !== 'undefined') {
            const words = sentence.split(' ')

            return words.map((word) => {
                if (word.length > 1) {
                    return word[0] + word.substring(1).toLowerCase()
                } else {
                    return word[0]
                }
            }).join(" ")
        }

        return sentence
    }

    phoneNumberCorrection = (phone) => {

        if (typeof phone !== 'undefined' && phone !== '') {

            const removeSpaces = phone.replace(' ', '')
            const removeTrace = removeSpaces.replace('-', '')

            //check DDI
            if (removeTrace[0] === '+') {
                return removeTrace
            }

            //remove first zero DDD
            if (removeTrace[0] === '0') {
                if (removeTrace.slice(1).length >= 10) { //correct number already
                    return '+55 '
                        + (removeTrace.slice(1)).slice(0, 2)
                        + ' '
                        + ((removeTrace.slice(1)).slice(2)).slice(0, ((removeTrace.slice(1)).slice(2)).length - 4)
                        + ' '
                        + ((removeTrace.slice(1)).slice(2)).slice(((removeTrace.slice(1)).slice(2)).length - 4, ((removeTrace.slice(1)).slice(2)).length)
                } else { //incorrect number
                    return ''
                }
            } else {
                if (removeTrace.length >= 10) { //correct number already
                    return '+55 '
                        + removeTrace.slice(0, 2)
                        + ' '
                        + (removeTrace.slice(2)).slice(0, (removeTrace.slice(2)).length - 4)
                        + ' '
                        + (removeTrace.slice(2)).slice((removeTrace.slice(2)).length - 4, (removeTrace.slice(2)).length)
                } else { // without DDD
                    // console.log('INCORRETO: ' + phone)
                    return '+55 11 '
                        + removeTrace.slice(0, removeTrace.length - 4)
                        + ' '
                        + removeTrace.slice(removeTrace.length - 4, removeTrace.length)
                }
            }
        }
        return ''
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Lista de clientes</h3> ({this.state.clients.length} clientes na base)

                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Telefone</th>
                                        <th>Primeira visita</th>
                                        <th>Última visita</th>
                                        <th>Aniversário</th>
                                        {/* <th>Ticket acumulado</th> */}
                                        <th>Ticket mês passado</th>
                                        <th>Ticket mês retrasado</th>
                                        <th>Situação (90dias)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clients
                                            .map((client) =>
                                                <tr key={client._id}>
                                                    <td>{this.uppercaseEachWords(client.name)}</td>
                                                    <td>{this.phoneNumberCorrection(client.phone1)}</td>
                                                    <td>{moment.utc(new Date(client.first_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{moment.utc(new Date(client.last_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{client.birthday ? moment.utc(new Date(client.birthday)).format('MMMM/YYYY') : "-"}</td>
                                                    {/* <td>R$ {(typeof client.ticket !== 'undefined') ? (client.ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td> */}
                                                    <td>R$ {(typeof client.last_ticket !== 'undefined') ? (client.last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    <td>R$ {(typeof client.last_last_ticket !== 'undefined') ? (client.last_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    <td style={client.active ? { color: '#24d366' } : { color: '#ed4545' }}>{client.active ? 'Ativo' : 'Inativo'}</td>
                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Clients;