import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import React from "react"
import Home from './components/Home'
import Campaigns from './components/Campaigns'
import ImportData from './components/ImportData'
import Bi from './components/Bi'
import Clients from './components/Clients'
import Configs from './components/Configs'
import Login from './components/Login'
import ClientsBirthday from './components/ClientsBirthday'
import ClientsNew from './components/ClientsNew'
import ClientsMissyou from './components/ClientsMissyou'
import { BrowserRouter, Link, Route, Routes, Navigate } from 'react-router-dom'
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap'

class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isAuth: false,
      lastPage: '/login',
      isMobile: (window.innerWidth < 700)
    }

  }

  componentDidMount() {
    this.checkIsAuth()
    this.checkLocalStorage()

  }

  componentWillUnmount() {

  }

  checkIsAuth = () => {
    if (localStorage.getItem("tokenBiwhot")) {
      this.setState({ isAuth: true })
      this.setState({ lastPage: '/' + document.URL.split('/')[document.URL.split('/').length - 1] })
    } else {
      this.setState({ isAuth: false })
      localStorage.removeItem("tokenBiwhot")
      localStorage.removeItem("userId")
      localStorage.removeItem("wToken")
      localStorage.removeItem("wInstance")
      localStorage.removeItem("usernameBiwhot")
    }
  }

  checkLocalStorage = () => {
    window.addEventListener('tokenBiwhot', () => {
      this.setState({ isAuth: true })
      window.location.href = "/"
    })
  }

  tryLogout = () => {
    this.setState({ isAuth: false })
    localStorage.removeItem("tokenBiwhot")
    localStorage.removeItem("userId")
    localStorage.removeItem("wToken")
    localStorage.removeItem("wInstance")
    localStorage.removeItem("usernameBiwhot")
  }


  render() {
    return (
      <div className="App" >

        <BrowserRouter>

          {this.state.isAuth &&
            <Navbar bg="dark" data-bs-theme="dark" fixed="top" show="false">
              <Container>

                {this.state.isMobile && <Nav className="me-auto" variant="underline">
                  <NavDropdown title="Menu">
                    <NavDropdown.Item><Nav.Link as={Link} to="/">Início</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item><Nav.Link as={Link} to="/campaigns">Campanhas</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item><Nav.Link as={Link} to="/bi">B.I.</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item><Nav.Link as={Link} to="/clients">Clientes</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Nav.Link as={Link} to="/configs">Configurações</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item><Nav.Link as={Link} onClick={() => { this.tryLogout() }}>Sair</Nav.Link></NavDropdown.Item>
                  </NavDropdown>
                </Nav>}

                {!this.state.isMobile && <Navbar.Brand href="/">
                  <img
                    alt=""
                    src="./images/5asec_logo.png"
                    width="90px"
                    height="auto"
                    className="d-inline-block align-top"
                  />
                </Navbar.Brand>}
                {!this.state.isMobile && <Nav className="me-auto" variant="underline">
                  <Nav.Link as={Link} to="/">Início</Nav.Link>
                  <Nav.Link as={Link} to="/campaigns">Campanhas</Nav.Link>
                  <Nav.Link as={Link} to="/bi">B.I.</Nav.Link>
                  <Nav.Link as={Link} to="/clients">Clientes</Nav.Link>
                  <Nav.Link as={Link} to="/importdata">Importar dados</Nav.Link>
                </Nav>}

                <Nav className="justify-content-end" variant="underline">
                  {!this.state.isMobile && <Nav.Link as={Link} to="/configs">Configurações</Nav.Link>}
                  {!this.state.isMobile && <Nav.Link as={Link} onClick={() => { this.tryLogout() }}>Sair</Nav.Link>}
                  {this.state.isMobile && <>
                    <Navbar.Brand href="/">
                      <img
                        alt=""
                        src="./images/5asec_logo.png"
                        width="90px"
                        height="auto"
                        className="d-inline-block align-top"
                      />
                    </Navbar.Brand>
                  </>}
                </Nav>

              </Container>
            </Navbar>}

          {!this.state.isAuth && <Navigate to="/login" />}
          {this.state.isAuth && <Navigate to={this.lastPage} />}

          <Routes>
            <Route path='/login' index element={<Login />}></Route>
            <Route path='/' index element={<Home />}></Route>
            <Route path='/campaigns' index element={<Campaigns />}></Route>
            <Route path='/bi' index element={<Bi />}></Route>
            <Route path='/clients' index element={<Clients />}></Route>
            <Route path='/importdata' index element={<ImportData />}></Route>
            <Route path='/configs' index element={<Configs />}></Route>

            <Route path='/clientsbirthday/:id' index element={<ClientsBirthday />}></Route>
            <Route path='/clientsnew/:id' index element={<ClientsNew />}></Route>
            <Route path='/clientsmissyou/:id' index element={<ClientsMissyou />}></Route>
          </Routes>

        </BrowserRouter>

      </div>
    )
  }

}

export default App;
